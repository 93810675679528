import { useCallback, useEffect, useState } from 'react';
import { NavigationBottom } from '../../../../components/engbers-specific/navigation-bottom';
import { useRouter } from 'next/router';
import { useMessageActions } from 'state/messages/actions';
import { useRecoilValueLoadable } from 'recoil';
import { unreadAllCount } from 'state/messages/atoms';
import localforage from 'localforage';
const EngbersBottomBarTastic = ({
  data
}) => {
  const router = useRouter();
  const {
    setAllMessages,
    setAllNews,
    setAllRealNews
  } = useMessageActions();
  const [hasGottenMessage, setHasGottenMessage] = useState(false);
  const unreadAllCountNow = useRecoilValueLoadable(unreadAllCount);

  // Puts our messages into state to reuse them all over the app
  useEffect(() => {
    setAllRealNews(data.newsItems);
  }, [data.newsItems, setAllNews]);
  const updateMessages = useCallback((cleverPush: any[]) => {
    setHasGottenMessage(true);
    cleverPush.push(['getNotifications', notifications => {
      const messages = notifications.map(notification => ({
        itemId: notification?._id,
        title: notification?.title,
        text: notification?.text,
        tagNames: notification?.tagNames || [],
        mediaUrl: notification?.mediaThumbnailUrl
      }));
      const messageTagCategoryItems = messages.filter(notification => {
        return notification.tagNames.includes('mitteilungen') || !notification.tagNames?.length;
      });
      const newsTagCategoryItems = messages.filter(notification => {
        return notification.tagNames.includes('neuigkeiten');
      });
      setAllMessages(messageTagCategoryItems);
      setAllNews(newsTagCategoryItems);
      setTimeout(() => updateMessages(cleverPush), 5000);
    }]);
  }, []);
  useEffect(() => {
    // Update badge count when unread all count changes.
    // This important especially when push notifications are disabled and badge count is not updated outside the app
    if (unreadAllCountNow.state === 'hasValue') {
      localforage.setItem('badge-count', unreadAllCountNow.contents || 0);
      // @ts-ignore
      if (typeof navigator.setAppBadge === 'function') {
        (navigator as any).setAppBadge(unreadAllCountNow.contents || 0);
      }
    }
  }, [unreadAllCountNow.state]);
  const updateMessagesLoop = useCallback(() => {
    if (window.CleverPush) {
      updateMessages(window.CleverPush);
    } else if (!hasGottenMessage) {
      setTimeout(updateMessagesLoop, 1000);
    }
  }, [hasGottenMessage, updateMessages]);
  useEffect(() => {
    updateMessagesLoop();
  }, [updateMessagesLoop]);
  return <NavigationBottom activeId={router.asPath === '/' ? data.navigationItems?.find(item => item.reference?.pageFolder?._url === '/')?.label : data.navigationItems?.filter(item => item.reference?.pageFolder?._url !== '/').find(item => router.asPath.includes(item.reference?.pageFolder?._url))?.label} items={data.navigationItems.map(item => {
    return {
      id: item.label,
      counter: item.hasMessageCounter && unreadAllCountNow?.state === 'hasValue' ? unreadAllCountNow?.contents : undefined,
      icon: item.iconId,
      label: item.label,
      reference: item.reference
    };
  })} data-sentry-element="NavigationBottom" data-sentry-component="EngbersBottomBarTastic" data-sentry-source-file="index.tsx" />;
};
export default EngbersBottomBarTastic;