import { CreditVoucher } from '@engbers/components/online-shops/credit-voucher';
import { SectionLayout } from '@engbers/components';
interface ICreditVouchersTasticData {
  placeholderText?: string;
  labelYourCredit?: string;
}
interface ICreditVouchersTasticComponent {
  data: ICreditVouchersTasticData;
}
export const CreditVouchersTastic: React.FC<ICreditVouchersTasticComponent> = ({
  data
}) => {
  return <SectionLayout data-sentry-element="SectionLayout" data-sentry-component="CreditVouchersTastic" data-sentry-source-file="index.tsx">
      <CreditVoucher placeholderText={data.placeholderText} labelYourCredit={data.labelYourCredit} data-sentry-element="CreditVoucher" data-sentry-source-file="index.tsx" />
    </SectionLayout>;
};