import React from 'react';
import { ResetPasswordForm } from '../../../../components/engbers-specific/reset-password-form';
import { Block } from '@engbers/components/block';
import { Typography } from '@engbers/components/typography';
export interface Props {
  data: {};
}
export const ResetPasswordTastic: React.FC<Props> = ({
  data
}) => {
  return <>
      <div style={{
      maxWidth: '400px',
      margin: '0 auto',
      padding: '0 20px'
    }}>
        <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <Typography size="base" weight="semi-bold" transform="uppercase" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {'Neues Passwort'}
          </Typography>
        </Block>
        <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <ResetPasswordForm data-sentry-element="ResetPasswordForm" data-sentry-source-file="index.tsx" />
        </Block>
      </div>
    </>;
};