import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { Button, Block, Link, AccordionItem, SectionLayout } from '@engbers/components';
interface IRegistrationWidgetData {
  registrationBenefitsLink?: IFTLinkReference | IFTPageFolderReference;
  registrationBenefitsLinkLabel?: string;
  registrationButtonLabel?: string;
  registrationCTATargetLink?: IFTLinkReference | IFTPageFolderReference;
  registrationSectionTitle?: string;
  registrationInitiallyOpen?: boolean;
}
interface IRegistrationWidgetTasticComponent {
  data: IRegistrationWidgetData;
}
export const RegistrationWidgetTastic: React.FC<IRegistrationWidgetTasticComponent> = ({
  data
}) => {
  const router = useRouter();
  const onRegisterClickCallback = useCallback(() => {
    router.push(getReferenceTarget(data.registrationCTATargetLink));
  }, [router, data.registrationCTATargetLink]);
  return <SectionLayout data-sentry-element="SectionLayout" data-sentry-component="RegistrationWidgetTastic" data-sentry-source-file="index.tsx">
      <AccordionItem isInitiallyOpen={data.registrationInitiallyOpen} label={data.registrationSectionTitle || 'Sie sind neu hier?'} data-sentry-element="AccordionItem" data-sentry-source-file="index.tsx">
        <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <Button size="large" onClick={onRegisterClickCallback} label={data.registrationButtonLabel || 'Konto anlegen'} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginLeft={3} marginRight={3} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
            <Link reference={data.registrationBenefitsLink} data-sentry-element="Link" data-sentry-source-file="index.tsx">
              {data.registrationBenefitsLinkLabel || 'Entdecken Sie die Vorteile der engbersCard'}
            </Link>
          </Block>
        </Block>
      </AccordionItem>
    </SectionLayout>;
};