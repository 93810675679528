import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import { useAccount } from '@frontastic-engbers/lib';
import { IFTLinkReference, IFTPageFolderReference, TMediaItem } from '@frontastic-engbers/types/engbers-custom';
import { AccordionItem, Block, Button, EmailVerification, HeaderLogin, Link, LoginForm, MigrationLoginForm, Typography } from '@engbers/components';
interface ILoginPageData {
  emailInputPlaceholder?: string;
  emailTooltipContent?: string;
  emailTooltipHeadline?: string;
  firstTimeInShopLabel?: string;
  homepageRedirectLink?: IFTLinkReference | IFTPageFolderReference;
  loginSectionTitle?: string;
  logo?: TMediaItem;
  logoWidth?: string;
  migrationButtonLabel?: string;
  migrationHidden?: boolean;
  migrationEmailPlaceholder?: string;
  migrationEmailTooltipContent?: string;
  migrationEmailTooltipHeadline?: string;
  migrationLoginSectionTitle?: string;
  migrationFirstTimeInShopLabel?: string;
  migrationVerifyInformation?: string;
  migrationVerifyBirthdate?: string;
  passwordForgotLinkLabel?: string;
  passwordForgotLink?: IFTLinkReference | IFTPageFolderReference;
  passwordForgottenTooltipContent?: string;
  passwordForgottenTooltipHeadline?: string;
  passwordInputPlaceholder?: string;
  passwordTooltipContent?: string;
  passwordTooltipHeadline?: string;
  loginButtonLabel?: string;
  redirectLink?: IFTLinkReference | IFTPageFolderReference;
  registerLink?: IFTLinkReference | IFTPageFolderReference;
  registrationBenefitsLink?: IFTLinkReference | IFTPageFolderReference;
  registrationBenefitsLinkLabel?: string;
  registrationButtonLabel?: string;
  registrationCTATargetLink?: IFTLinkReference | IFTPageFolderReference;
  registrationSectionTitle?: string;
  subtitle?: string;
  title?: string;
  registrationInitiallyOpen?: boolean;
  loginInitiallyOpen?: boolean;
  migrationInitiallyOpen?: boolean;
}
interface ILoginPageTasticComponent {
  data: ILoginPageData;
}
export const LoginPageTastic: React.FC<ILoginPageTasticComponent> = ({
  data
}) => {
  const {
    loggedIn,
    loaded
  } = useAccount();
  const router = useRouter();
  const onRegisterClickCallback = useCallback(() => {
    router.push(getReferenceTarget(data.registrationCTATargetLink));
  }, [router, data.registrationCTATargetLink]);
  useEffect(() => {
    if (loaded && loggedIn) {
      router.push('/');
    }
  }, [loaded, loggedIn, router]);
  return <div data-sentry-component="LoginPageTastic" data-sentry-source-file="index.tsx">
      <HeaderLogin headline={data.title || 'Herzlich Willkommen'} subHeadline={data.subtitle || 'In der Engbers-App'} data-sentry-element="HeaderLogin" data-sentry-source-file="index.tsx" />
      <div style={{
      maxWidth: '400px',
      margin: '0 auto',
      padding: '0 20px'
    }}>
        <EmailVerification redirectLink={data.homepageRedirectLink} usePushNotification={true} tokenParam="doiId" data-sentry-element="EmailVerification" data-sentry-source-file="index.tsx" />
        
        {!data.migrationHidden && <AccordionItem isInitiallyOpen={data.migrationInitiallyOpen} label={data.migrationLoginSectionTitle || 'Online-Konto freischalten'}>
            <MigrationLoginForm {...data} />
            <Typography>{data.migrationFirstTimeInShopLabel || '1. Mal im Online-Shop mit engbersCard?'}</Typography>
          </AccordionItem>}

        <AccordionItem isInitiallyOpen={data.loginInitiallyOpen} label={data.loginSectionTitle || 'Anmelden | Mein Konto'} data-sentry-element="AccordionItem" data-sentry-source-file="index.tsx">
          <LoginForm labels={data} homepageRedirectLink={data.homepageRedirectLink} passwordForgotLink={data.passwordForgotLink} data-sentry-element="LoginForm" data-sentry-source-file="index.tsx" />
          {data.firstTimeInShopLabel && <Typography>{data.firstTimeInShopLabel}</Typography>}
        </AccordionItem>

        <AccordionItem isInitiallyOpen={data.registrationInitiallyOpen} label={data.registrationSectionTitle || 'Sie sind neu hier?'} data-sentry-element="AccordionItem" data-sentry-source-file="index.tsx">
          <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
            <Button size="large" onClick={onRegisterClickCallback} label={data.registrationButtonLabel || 'Konto anlegen'} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
          </Block>
          <Block marginLeft={3} marginRight={3} data-sentry-element="Block" data-sentry-source-file="index.tsx">
            <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
              <Link reference={data.registrationBenefitsLink} data-sentry-element="Link" data-sentry-source-file="index.tsx">
                {data.registrationBenefitsLinkLabel || 'Entdecken Sie die Vorteile der engbersCard'}
              </Link>
            </Block>
          </Block>
        </AccordionItem>
      </div>
    </div>;
};