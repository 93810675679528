import { AccordionItem } from '@engbers/components';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import styles from './accordion-item.module.scss';
const EngbersAccordionItemTastic = ({
  data
}) => {
  return <AccordionItem label={data.label} isInitiallyOpen={data.isInitiallyOpen} data-sentry-element="AccordionItem" data-sentry-component="EngbersAccordionItemTastic" data-sentry-source-file="index.tsx">
      <ReactMarkdown className={styles.text} data-sentry-element="ReactMarkdown" data-sentry-source-file="index.tsx">{data.content}</ReactMarkdown>
    </AccordionItem>;
};
export default EngbersAccordionItemTastic;