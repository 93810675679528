import { IModal } from '@frontastic-engbers/types/engbers-custom';
import styles from './modal.module.scss';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { motion } from 'framer-motion';
import { Block, Button } from '@engbers/components';
const backdropVariants = {
  initial: {
    opacity: 0
  },
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
};
const modalVariants = {
  initial: () => ({
    opacity: 0,
    y: 'calc(-50% + 20px)',
    x: '-50%'
  }),
  enter: () => ({
    opacity: 1,
    y: '-50%',
    x: '-50%'
  }),
  exit: {
    opacity: 0,
    y: 'calc(-50% + 20px)',
    x: '-50%'
  }
};
export const Modal: React.FC<IModal> = ({
  onClose,
  content,
  id,
  hasCloseButton = true,
  title,
  hasOkayButton,
  canCloseByBackdropClick = false,
  isLarge = false,
  isMedium = false
}) => {
  const {
    removeModal
  } = useModalActions();
  const maxWidth = isMedium ? 600 : isLarge ? 900 : undefined;
  const closeModal = () => {
    removeModal();
    onClose?.();
  };
  return <div data-sentry-component="Modal" data-sentry-source-file="index.tsx">
      <motion.div variants={backdropVariants} initial={'initial'} animate={'enter'} exit={'exit'} className={styles.backdrop} onClick={() => {
      if (canCloseByBackdropClick) {
        closeModal();
      }
    }} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />

      <motion.div className={styles.modalContainer} variants={modalVariants} initial={'initial'} animate={'enter'} transition={{
      type: 'spring',
      bounce: 0
    }} exit={'exit'} style={{
      maxWidth: maxWidth + 'px'
    }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        {hasCloseButton && <div className={styles.closeButton} onClick={closeModal}>
            <div className={styles.closeIcon} />
          </div>}
        <div className={styles.scrollContainer}>
          {title && <div className={styles.title}>{title}</div>}

          <div className={styles.content}>
            {content}
            {hasOkayButton && <Block marginTop={8}>
                <Button size="large" onClick={closeModal} hasIcon={false} label="Okay" />
              </Block>}
          </div>
        </div>
      </motion.div>
    </div>;
};