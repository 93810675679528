import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import styles from './footer.module.scss';
import Image from '@frontastic-engbers/lib/lib/image';
export const Footer = ({
  linkGroups,
  socialMediaGroups,
  logo
}) => {
  return <footer className={styles.wrap} data-sentry-component="Footer" data-sentry-source-file="index.tsx">
      <div>
        {linkGroups.map((group, groupIndex) => {
        return <div key={`footer-group-${groupIndex}`} className={styles.linkGroup}>
              <div className={styles.linkGroupHeadline}>{group.headline}</div>
              <div className={styles.linkGroupLinksWrap}>
                {group.links.map((link, linkIndex) => {
              return <ReferenceLink target={link.reference} className={styles.link} key={`footer-group-${groupIndex}-${linkIndex}`}>
                      {link.label}
                    </ReferenceLink>;
            })}
              </div>
            </div>;
      })}
      </div>
      <div className={styles.socialMediaOuterWrap}>
        <div className={styles.shareHeadline}>Folge uns:</div>
        <div className={styles.socialMediaWrap}>
          {socialMediaGroups?.map(item => {
          return <div key={`social-media-item-${item.name}`} className={styles.socialMediaItem}>
                <ReferenceLink target={item.reference} className={styles.link}>
                  <Image alt={`Social Media Icon ${item.name}`} src={item.image?.media.file} />
                </ReferenceLink>
              </div>;
        })}
        </div>
      </div>
      <div className={styles.copyrightRow}>
        <div className={styles.logoWrap}>
          <Image alt={'engbers Logo'} src={logo} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </div>
        <div className={styles.copyright}>© engbers {new Date().getFullYear()}</div>
      </div>
    </footer>;
};