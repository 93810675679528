import { useEffect, useState } from 'react';
import { Message } from './components/message';
import styles from './messages.module.scss';
import { useMessageActions } from 'state/messages/actions';
import { useRecoilState, useRecoilValue } from 'recoil';
import { messagesState } from 'state/messages/atoms';
export const Messages = () => {
  const {
    markAllMessagesRead
  } = useMessageActions();
  const messages = useRecoilValue(messagesState);
  useEffect(() => {
    markAllMessagesRead();
  }, []);
  return <div data-sentry-component="Messages" data-sentry-source-file="index.tsx">
      {messages.allMessages.map(message => {
      return <div className={styles.messageWrap} key={`message-${message.itemId}`}>
            <Message image={message.mediaUrl} headline={message.title} description={message.text} />
          </div>;
    })}
    </div>;
};