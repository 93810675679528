import { Typography } from '@engbers/components/typography';
import { Block, Button, InputText, PasswordCheckList } from '@engbers/components';
import React, { useCallback, useState } from 'react';
import { useAccount } from '@frontastic-engbers/lib';
import { useRouter } from 'next/router';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { PasswordCheckListHelper } from '@frontastic-engbers/helpers/passwordCheckListHelper';
type ResetPasswordFormData = {
  newPassword: string;
  newPasswordRepeat: string;
};
const validate = (data: ResetPasswordFormData) => {
  return {
    newPassword: data.newPassword !== '',
    newPasswordRepeat: data.newPassword === data.newPasswordRepeat
  };
};

// eslint-disable-next-line no-empty-pattern
export const ResetPasswordForm: React.FC<any> = ({}) => {
  const router = useRouter();
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    formatMessage: formatSuccessMessage
  } = useFormat({
    name: 'success'
  });
  const {
    formatMessage: formatAccountMessage
  } = useFormat({
    name: 'account'
  });
  const [loading, setLoading] = useState(false);
  const [isPassInputFocused, setIsPassInputFocused] = useState<boolean>(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const {
    pushNotification
  } = useToastNotificationsActions();
  const [errors, setErrors] = useState({
    newPassword: true,
    newPasswordRepeat: true
  });
  const [newPasswordErrors, setNewPasswordErrors] = useState<{
    valid: boolean;
    message: string;
  } | null>(null);
  const [formData, setFormData] = useState<ResetPasswordFormData>({
    newPassword: '',
    newPasswordRepeat: ''
  });
  const resetPasswordForm = useCallback(() => {
    setFormData({
      newPassword: '',
      newPasswordRepeat: ''
    });
  }, []);
  const {
    token: token
  } = router.query;
  const {
    resetPassword
  } = useAccount();
  const validateNewPassword = (e?: React.ChangeEvent<HTMLInputElement>) => {
    const ruleDefinitions: any = PasswordCheckListHelper.ruleDefinitions(e ? e.target.value : formData.newPassword, 6);
    const rulesKeys: string[] = Object.keys(ruleDefinitions).filter(key => !ruleDefinitions[key].valid);
    rulesKeys.forEach(key => {
      if (!ruleDefinitions[key].valid) {
        setErrors({
          ...errors,
          newPassword: ruleDefinitions[key].valid
        });
        setNewPasswordErrors(ruleDefinitions[key]);
      }
    });
    if (!rulesKeys.length) {
      setErrors({
        ...errors,
        newPassword: true
      });
      setNewPasswordErrors({
        valid: true,
        message: ''
      });
    }
  };
  const handleSubmit = async (e: any) => {
    const err = validate(formData);
    validateNewPassword();
    setSuccess('');
    setError('');
    setErrors(err);
    if (Object.values(err).some(error => !error) || !newPasswordErrors || !newPasswordErrors.valid) {
      return;
    }
    e.preventDefault();
    setLoading(true);
    const res = await resetPassword(token as string, formData.newPassword);
    if ((res as any).error) {
      setError(formatErrorMessage({
        id: 'wentWrong',
        defaultMessage: 'Sorry. Something went wrong..'
      }));
      pushNotification(formatErrorMessage({
        id: 'passwordChangeFailed'
      }), 'error');
    } else {
      resetPasswordForm();
      pushNotification(formatSuccessMessage({
        id: 'updatedPassword'
      }), 'success');
    }
    setLoading(false);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  return <>
      <form onSubmit={handleSubmit}>
        {success ? <Typography color={'success'}>{success}</Typography> : null}
        {error ? <Typography color={'danger'}>{error}</Typography> : null}
        <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <InputText id="newPassword" name="newPassword" type="password" onChange={e => {
          handleChange(e);
          validateNewPassword(e);
        }} onFocus={() => setIsPassInputFocused(true)} onBlur={e => {
          setIsPassInputFocused(false);
          validateNewPassword(e);
        }} placeholder={formatAccountMessage({
          id: 'password.new'
        })} value={formData.newPassword} errorMessage={newPasswordErrors && !newPasswordErrors.valid ? newPasswordErrors.message : undefined} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        {isPassInputFocused ? <Block marginBottom={4}>
            <PasswordCheckList rules={['minLength', 'lowercase', 'capital', 'number', 'specialChar']} minLength={6} value={formData.newPassword} />
          </Block> : null}
        <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <InputText id="newPasswordRepeat" name="newPasswordRepeat" type="password" onChange={handleChange} onBlur={() => {
          setIsPassInputFocused(false);
          setErrors({
            ...errors,
            newPasswordRepeat: formData.newPassword === formData.newPasswordRepeat
          });
        }} placeholder={formatAccountMessage({
          id: 'password.repeat'
        })} value={formData.newPasswordRepeat} errorMessage={!errors.newPasswordRepeat ? formatErrorMessage({
          id: 'password.noMatch'
        }) : undefined} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <Button isLoading={loading} onClick={handleSubmit} label={formatAccountMessage({
          id: 'password.save'
        })} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
        </Block>
      </form>
    </>;
};