import React, { useState } from 'react';
import { IconCustom } from '@engbers/components';
const StyleGuideInputs: React.FC = () => {
  const [showInfo, setShowInfo] = useState<boolean>(false);
  return <div className="dark:bg-encom-900 mb-10 border border-solid border-slate-300 dark:text-white" data-sentry-component="StyleGuideInputs" data-sentry-source-file="style-guide-inputs.tsx">
      <h2 className="mx-6 my-4 dark:text-white">Inputs</h2>

      <div className="my-3 flex items-center justify-start gap-3 p-4 align-middle">
        <div className="text-xs-bold mx-3 w-[10%] text-left">Default</div>
        <div className="mx-3 w-[40%]">
          <div className="floating-label-group">
            <input id="input-1" name="text" type="text" placeholder="Label" // Label is IMPORTANT to add with value in order the floating label to work properly.
          required className="bg-encom-50 block h-[48px] w-full border border-none px-4 pt-4 focus:outline-none focus:ring-transparent" />
            <label htmlFor="input-1" className="text-encom-500">
              Label
              <span className="text-danger">*</span>
            </label>
          </div>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start gap-3 p-4 align-middle">
        <div className="text-xs-bold mx-3 w-[10%] text-left">Info icon</div>
        <div className="mx-3 w-[40%]">
          <div className="floating-label-group">
            <input id="input-info" name="text" type="text" placeholder="Label" // Label is IMPORTANT to add with value in order the floating label to work properly.
          required className="bg-encom-50 block h-[48px] w-full border border-none px-4 pt-4 focus:outline-none focus:ring-transparent" />
            <label htmlFor="input-info" className="text-encom-500">
              Label
              <span className="text-danger">*</span>
            </label>

            <div className="info-group">
              <div className="info-group-btn" onClick={() => setShowInfo(!showInfo)}>
                <IconCustom icon="Info" width={22} className="m-2" data-sentry-element="IconCustom" data-sentry-source-file="style-guide-inputs.tsx" />
              </div>
              {showInfo && <>
                  <div className="info-group-content p-3">
                    <span>Here goes some dummy text for info</span>
                  </div>
                  <div className="info-group-layer" onClick={() => setShowInfo(false)} />
                </>}
            </div>
          </div>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start gap-3 p-4 align-middle">
        <div className="text-xs-bold mx-3 w-[10%] text-left">Hint</div>
        <div className="mx-3 w-[40%]">
          <div className="floating-label-group">
            <input id="input-2" name="text" type="text" placeholder="Label" // Label is IMPORTANT to add with value in order the floating label to work properly.
          required className="bg-encom-50 block h-[48px] w-full border border-none px-4 pt-4 focus:outline-none focus:ring-transparent" />
            <label htmlFor="input-2" className="text-encom-500">
              Label
              <span className="text-danger">*</span>
            </label>
          </div>
          <span className="text-encom-700 text-xs dark:text-white">Hint message here</span>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start gap-3 p-4 align-middle">
        <div className="text-xs-bold mx-3 w-[10%] text-left">Error</div>
        <div className="mx-3 w-[40%]">
          <div className="floating-label-group">
            <input id="input-3" name="text" type="text" placeholder="Label" // Label is IMPORTANT to add with value in order the floating label to work properly.
          required className="bg-encom-50 block h-[48px] w-full border border-none px-4 pt-4 focus:outline-none focus:ring-transparent" />
            <label htmlFor="input-3" className="text-encom-500">
              Label
              <span className="text-danger">*</span>
            </label>
          </div>
          <span className="text-xs text-danger">Error message here</span>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start gap-3 p-4 align-middle">
        <div className="text-xs-bold mx-3 w-[10%] text-left">Success</div>
        <div className="mx-3 w-[40%]">
          <div className="floating-label-group">
            <input id="input-4" name="text" type="text" placeholder="Label" // Label is IMPORTANT to add with value in order the floating label to work properly.
          required className="bg-encom-50 block h-[48px] w-full border border-none px-4 pt-4 focus:outline-none focus:ring-transparent" />
            <label htmlFor="input-4" className="text-encom-500">
              Label
              <span className="text-danger">*</span>
            </label>
          </div>
          <span className="text-xs text-success">Success message here</span>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start gap-3 p-4 align-middle">
        <div className="text-xs-bold mx-3 w-[10%] text-left">Disabled</div>
        <div className="mx-3 w-[40%]">
          <div className="floating-label-group">
            <input id="input-5" name="text" type="text" placeholder="Label" // Label is IMPORTANT to add with value in order the floating label to work properly.
          required className="bg-encom-50 block h-[48px] w-full border border-none px-4 pt-4 focus:outline-none focus:ring-transparent" disabled />
            <label htmlFor="input-5" className="text-encom-500">
              Label
              <span className="text-danger">*</span>
            </label>
          </div>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start gap-3 p-4 align-middle">
        <div className="text-xs-bold mx-3 w-[10%] text-left">Default</div>
        <div className="mx-3 w-[40%]">
          <div className="input-radio-group">
            <input id="input-6" name="radio" type="radio" required className="border-encom-200 bg-encom-50 hover:border-encom-900 focus:ring-transparent" />
            <label htmlFor="input-6" className="text-encom-500 dark:text-white">
              Radio Button
            </label>
          </div>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start gap-3 p-4 align-middle">
        <div className="text-xs-bold mx-3 w-[10%] text-left">Disabled</div>
        <div className="mx-3 w-[40%]">
          <div className="input-radio-group">
            <input id="input-7" name="radio" type="radio" required className="border-encom-200 bg-encom-50 hover:border-encom-900 focus:ring-transparent" disabled />
            <label htmlFor="input-7" className="text-encom-500 dark:text-white">
              Radio Button
            </label>
          </div>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start gap-3 p-4 align-middle">
        <div className="text-xs-bold mx-3 w-[10%] text-left">Error</div>
        <div className="mx-3 w-[40%]">
          <div className="input-radio-group">
            <input id="input-8" name="radio" type="radio" required className="bg-encom-50 hover:border-encom-900 border-danger focus:ring-transparent" />
            <label htmlFor="input-8" className="text-encom-500 dark:text-white">
              Radio Button
            </label>
          </div>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start gap-3 p-4 align-middle">
        <div className="text-xs-bold mx-3 w-[10%] text-left">Default</div>
        <div className="mx-3 w-[40%]">
          <div className="input-checkbox-group">
            <input id="input-9" name="checkbox" type="checkbox" required className="border-encom-200 bg-encom-50 hover:border-encom-900 focus:ring-transparent" />
            <label htmlFor="input-9" className="text-encom-500 dark:text-white">
              Checkbox
            </label>
          </div>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start gap-3 p-4 align-middle">
        <div className="text-xs-bold mx-3 w-[10%] text-left">Disabled</div>
        <div className="mx-3 w-[40%]">
          <div className="input-checkbox-group">
            <input id="input-10" name="checkbox" type="checkbox" required className="border-encom-200 bg-encom-50 hover:border-encom-900 focus:ring-transparent" disabled />
            <label htmlFor="input-10" className="text-encom-500 dark:text-white">
              Checkbox
            </label>
          </div>
        </div>
      </div>

      <div className="my-3 flex items-center justify-start gap-3 p-4 align-middle">
        <div className="text-xs-bold mx-3 w-[10%] text-left">Error</div>
        <div className="mx-3 w-[40%]">
          <div className="input-checkbox-group">
            <input id="input-11" name="checkbox" type="checkbox" required className="bg-encom-50 hover:border-encom-900 border-danger focus:ring-transparent" />
            <label htmlFor="input-11" className="text-encom-500 dark:text-white">
              Checkbox
            </label>
          </div>
        </div>
      </div>
    </div>;
};
export default StyleGuideInputs;