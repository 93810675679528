import React from 'react';
import { ForgotPasswordForm } from '../../../../components/engbers-specific/forgot-password-form';
import { Block, Link, SectionLayout, Typography, HeaderLogin } from '@engbers/components';
export interface Props {
  data: {
    inputTooltipContent: any;
    inputTooltipHeadline: string;
  };
}
export const ForgotPasswordTastic: React.FC<Props> = ({
  data
}) => {
  return <>
      <HeaderLogin headline="Herzlich Willkommen" subHeadline="In der Engbers-App" data-sentry-element="HeaderLogin" data-sentry-source-file="index.tsx" />
      <SectionLayout data-sentry-element="SectionLayout" data-sentry-source-file="index.tsx">
        <Block marginBottom={2} marginTop={8} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <Typography weight="semi-bold" size="xl" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {'Passwort zurücksetzen'}
          </Typography>
        </Block>
        <ForgotPasswordForm infoText={data.inputTooltipContent} infoTextHeadline={data.inputTooltipHeadline} data-sentry-element="ForgotPasswordForm" data-sentry-source-file="index.tsx" />
        <Block marginTop={8} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <Link href={'/login'} data-sentry-element="Link" data-sentry-source-file="index.tsx">Zurück zur Anmeldung</Link>
        </Block>
      </SectionLayout>
    </>;
};