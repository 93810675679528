import NotFound from './not-found';
import EngbersBottomBarTastic from './engbers/bottombar';
import EngbersContentNavigationTastic from './engbers/content-navigation';
import EngbersTeaserType1Tastic from './engbers/teaser-type-1';
import EngbersGreetingBannerTastic from './engbers/greeting-banner';
import EngbersImageTastic from './engbers/image';
import EngbersInfoBoxTastic from './engbers/infobox';
import EngbersCardTastic from './engbers/card';
import EngbersAccountBalanceTastic from './engbers/account-balance';
import { HeaderSecondLevelTastic } from './engbers/header-second-level';
import { MyDataFormTastic } from './engbers/my-data-form';
import { ChangePasswordFormTastic } from './engbers/change-password-form';
import { LoginPageTastic } from './engbers/login-page';
import { RegisterPageTastic } from './engbers/register-page';
import { ForgotPasswordTastic } from './engbers/forgot-password';
import { ResetPasswordTastic } from './engbers/reset-password';
import { OrderHistoryTastic } from './engbers/order-history';
import { SpacerTastic } from './engbers/spacer';
import EngbersAccordionItemTastic from './engbers/accordion-item';
import EngbersTabMenuTastic from './engbers/tab-menu';
import { ContactFormTastic } from './engbers/contact-form';
import { ContactFormTasticNew } from './engbers/contact-form-new';
import { MessagesTastic } from './engbers/messages';
import { CreditVouchersTastic } from './engbers/credit-vouchers';
import { VouchersTastic } from './engbers/vouchers';
import { NewsletterItemsTastic } from './engbers/newsletter-items';
import { NewsletterItemsTasticNew } from './engbers/newsletter-items-new';
import { FooterTastic } from './engbers/footer';
import { EngbersTextTastic } from './engbers/text';
import { EngbersImageTextTastic } from './engbers/image-text';
import { EngbersButtonTastic } from './engbers/button';
import { EngbersCategoryImageTextTastic } from './engbers/category-image-text';
import { EngbersToTheNewsletterTastic } from './engbers/to-the-newsletter';
import { HeaderTastic } from './engbers/header';
import { CouponsTastic } from './engbers/coupons';
import { LoginFormTastic } from './engbers/login-form';
import { MigrationFormTastic } from './engbers/migration-form';
import { RegistrationWidgetTastic } from './engbers/registration-widget';
import StyleGuideTastic from './style-guide';
import { MyAccountTastic } from './engbers/my-account';
import { EngbersNewsVisualizerTastic } from './engbers/news-visualizer';
import { EngbersUsercentricsTastic } from './engbers/usercentrics';
export const tastics = {
  'engbers/account/login-page': LoginPageTastic,
  'engbers/account/register': RegisterPageTastic,
  'engbers/account/forgot-password': ForgotPasswordTastic,
  // NOTE: We need to check if/how reset-password can even be implemented via OXID.
  // The emails are currently linking to the shop, not the PWA
  'engbers/account/reset-password': ResetPasswordTastic,
  'engbers/account/login-form': LoginFormTastic,
  'engbers/account/migration-form': MigrationFormTastic,
  'engbers/account/registration-widget': RegistrationWidgetTastic,
  // Engbers Tastics
  'engbers/bottombar': EngbersBottomBarTastic,
  'engbers/content-navigation': EngbersContentNavigationTastic,
  'engbers/image': EngbersImageTastic,
  'engbers/teaser-type-1': EngbersTeaserType1Tastic,
  'engbers/greeting-banner': EngbersGreetingBannerTastic,
  'engbers/infobox': EngbersInfoBoxTastic,
  'engbers/card': EngbersCardTastic,
  'engbers/account-balance': EngbersAccountBalanceTastic,
  'engbers/header-second-level': HeaderSecondLevelTastic,
  'engbers/my-data': MyDataFormTastic,
  'engbers/change-password': ChangePasswordFormTastic,
  'engbers/order-history': OrderHistoryTastic,
  'engbers/spacer': SpacerTastic,
  'engbers/accordion-item': EngbersAccordionItemTastic,
  'engbers/tab-menu': EngbersTabMenuTastic,
  'engbers/contact-form': ContactFormTastic,
  'engbers/contact-form-new': ContactFormTasticNew,
  'engbers/messages': MessagesTastic,
  'engbers/credit-vouchers': CreditVouchersTastic,
  'engbers/vouchers': VouchersTastic,
  'engbers/newsletter-items': NewsletterItemsTastic,
  'engbers/newsletter-items-new': NewsletterItemsTasticNew,
  'engbers/footer': FooterTastic,
  'engbers/text': EngbersTextTastic,
  'engbers/image-text': EngbersImageTextTastic,
  'engbers/button': EngbersButtonTastic,
  'engbers/category-image-text': EngbersCategoryImageTextTastic,
  'engbers/to-the-newsletter': EngbersToTheNewsletterTastic,
  'engbers/header': HeaderTastic,
  'engbers/coupons': CouponsTastic,
  'engbers/account/my-account': MyAccountTastic,
  'engbers/news-visualizer': EngbersNewsVisualizerTastic,
  'engbers/usercentricsbutton': EngbersUsercentricsTastic,
  'commercetools/ui/content/style-guide': StyleGuideTastic,
  default: NotFound
};