import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { RegistrationForm } from '@engbers/components/registration-form';
import { useAccount } from '@frontastic-engbers/lib/provider';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { HeaderLogin } from '@engbers/components';
export interface Props {
  data: any;
}
export const RegisterPageTastic: React.FC<Props> = ({
  data
}) => {
  const {
    formatMessage: formatAccountMessage
  } = useFormat({
    name: 'account'
  });
  const [initialState, setInitialState] = React.useState(undefined);
  const {
    loggedIn,
    loaded
  } = useAccount();
  const router = useRouter();
  useEffect(() => {
    try {
      const registerFormState = sessionStorage.getItem('registerFormState');
      if (registerFormState) {
        setInitialState(JSON.parse(registerFormState));
        sessionStorage.removeItem('registerFormState');
      }
    } catch (e) {}
  }, [typeof sessionStorage !== 'undefined' ? sessionStorage.registerFormState : undefined]);
  useEffect(() => {
    if (loaded && loggedIn) {
      router.push(data.homepageRedirectLink);
    }
  }, [loaded, loggedIn, router, data.homepageRedirectLink]);
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
        <title>
          {formatAccountMessage({
          id: 'sign.up',
          defaultMessage: 'Sign up'
        })}
        </title>
      </Head>
      <div>
        <HeaderLogin headline={data.topHeadline} subHeadline={data.topSubHeadline} data-sentry-element="HeaderLogin" data-sentry-source-file="index.tsx" />
        <RegistrationForm labels={{
        headline: data.headline,
        radioMister: data.radioMister,
        radioMs: data.radioMs,
        labelFirstName: data.labelFirstName,
        lastLastName: data.lastLastName,
        labelStreet: data.labelStreet,
        labelHouse: data.labelHouse,
        labelZip: data.labelZip,
        labelCity: data.labelCity,
        cityInfo: data.cityInfo,
        labelCountry: data.labelCountry,
        labelBirth: data.labelBirth,
        birthdateInfo: data.birthdateInfo,
        labelEmail: data.labelEmail,
        labelEmailConfirm: data.labelEmailConfirm,
        labelNewsletter: data.labelNewsletter,
        labelAdaniNewsletter: data.labelAdaniNewsletter,
        labelXXLNewsletter: data.labelXXLNewsletter,
        labelLetters: data.labelLetters,
        labelButtonRegister: data.labelButtonRegister,
        labelBack: data.labelBack,
        labelShoppingPreferenceHeadline: data.labelShoppingPreferenceHeadline,
        messageSuccess: data.messageSuccess,
        errorRequired: data.errorRequired,
        errorMatch: data.errorMatch,
        reCaptchaSiteKey: data?.reCaptchaSiteKeyData?.dataSource?.reCaptchaSiteKey,
        textGuidelinesMarkdown: data.textGuidelinesMarkdown,
        noStoreFound: data.noStoreFoundLabel,
        changeStore: data.changeStoreLabel
      }} initialState={initialState} data-sentry-element="RegistrationForm" data-sentry-source-file="index.tsx" />
      </div>
    </>;
};