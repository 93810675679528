import { useRecoilCallback } from 'recoil';
import { messagesState, unreadMessageCount, unreadNewsCount } from './atoms';
import localForage from 'localforage';

export interface IMessageActionsHook {
  setAllMessages: (messages: any[]) => void;
  setAllNews: (news: any[]) => void;
  setAllRealNews: (news: any[]) => void;
  markAllMessagesRead: () => void;
  markAllNewsRead: () => void;
}

export const useMessageActions = (): IMessageActionsHook => {
  const setAllMessages = useRecoilCallback(({ set }) => async (allMessages: any[]) => {
    set(messagesState, (prev) => {
      return {
        ...prev,
        allMessages: [...allMessages],
      };
    });
  });

  const setAllNews = useRecoilCallback(({ set }) => async (allNews: any[]) => {
    set(messagesState, (prev) => {
      return {
        ...prev,
        allNews: [...allNews],
      };
    });
  });

  const setAllRealNews = useRecoilCallback(({ set }) => async (allNews: any[]) => {
    set(messagesState, (prev) => {
      return {
        ...prev,
        allRealNews: [...allNews],
      };
    });
  });

  const markAllMessagesRead = useRecoilCallback(({ set, snapshot }) => async () => {
    const unreadNewsCountCurrent = await snapshot.getPromise(unreadNewsCount);
    localForage.setItem('badge-count', unreadNewsCountCurrent || 0);
    // @ts-ignore
    if (typeof navigator.setAppBadge === 'function') {
      (navigator as any).setAppBadge(unreadNewsCountCurrent || 0);
    }

    set(messagesState, (prev) => {
      return {
        ...prev,
        readMessages: [...prev.allMessages.map((mess) => mess.itemId)],
      };
    });
  });

  const markAllNewsRead = useRecoilCallback(({ set, snapshot }) => async () => {
    const unreadMessagesCountCurrent = await snapshot.getPromise(unreadMessageCount);
    localForage.setItem('badge-count', unreadMessagesCountCurrent || 0);
    // @ts-ignore
    if (typeof navigator.setAppBadge === 'function') {
      (navigator as any).setAppBadge(unreadMessagesCountCurrent || 0);
    }

    set(messagesState, (prev) => {
      return {
        ...prev,
        readNews: [...prev.allNews.map((news) => news.itemId)],
      };
    });
  });

  return {
    setAllMessages,
    setAllNews,
    setAllRealNews,

    markAllMessagesRead,
    markAllNewsRead,
  };
};
