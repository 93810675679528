import { Typography } from '@engbers/components';
import React from 'react';
const StyleGuideTypography: React.FC = () => <div className="dark:bg-encom-900 mb-10 border border-solid border-slate-300 dark:text-white" data-sentry-component="StyleGuideTypography" data-sentry-source-file="style-guide-typography.tsx">
    <h2 className="mx-6 my-4 dark:text-white">Typography</h2>
    <p className="mx-6 my-4 dark:text-white">
      Please make sure to just use the Typography React Component for Font Styling
    </p>
    <div className="flex items-center justify-between border-t border-solid border-slate-300 text-center">
      <div className="my-4 w-[10%]" />
      <div className="my-4 w-[40%]">Default</div>
      <div className="my-4 w-[40%]">Uppercase</div>
    </div>

    <div className="text-xs-bold mt-3 pl-3">Heading 1</div>
    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="mx-3 w-[40%] text-center">
        <Typography tag="h1" variant="heading-1" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
      <div className="mx-3 w-[40%] text-center">
        <Typography transform="uppercase" tag="h1" variant="heading-1" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
    </div>

    <div className="text-xs-bold mt-3 pl-3">Heading 2</div>
    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="mx-3 w-[40%] text-center">
        <Typography tag="h2" variant="heading-2" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
      <div className="mx-3 w-[40%] text-center">
        <Typography tag="h2" transform="uppercase" variant="heading-2" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
    </div>

    <div className="text-xs-bold mt-3 pl-3">Heading 3</div>
    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="mx-3 w-[40%] text-center">
        <Typography tag="h3" variant="heading-3" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
      <div className="mx-3 w-[40%] text-center">
        <Typography transform="uppercase" tag="h3" variant="heading-3" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
    </div>

    <div className="text-xs-bold mt-3 pl-3">Heading 4</div>
    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="mx-3 w-[40%] text-center">
        <Typography tag="h4" variant="heading-4" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
      <div className="mx-3 w-[40%] text-center">
        <Typography transform="uppercase" tag="h4" variant="heading-4" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
    </div>

    <div className="text-xs-bold mt-3 pl-3">Heading 5</div>
    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="mx-3 w-[40%] text-center">
        <Typography tag="h5" variant="heading-5" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
      <div className="mx-3 w-[40%] text-center">
        <Typography transform="uppercase" tag="h5" variant="heading-5" data-sentry-element="Typography" data-sentry-source-file="style-guide-typography.tsx">
          The quick brown fox jumps over the lazy dog.
        </Typography>
      </div>
    </div>

    <div className="mt-10 flex items-center justify-between text-center">
      <div className="mx-3 my-4 w-[20%]">Light</div>
      <div className="mx-3 my-4 w-[20%]">Regular</div>
      <div className="mx-3 my-4 w-[20%]">Medium</div>
      <div className="mx-3 my-4 w-[20%]">Bold</div>
    </div>

    <div className="text-xs-bold mt-3 pl-3">Link</div>
    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="mx-3 w-[20%] text-center">
        <a href="style-guide/style-guide-typography#" className="text-base-light link dark:link-dark">
          The quick brown fox jumps over the lazy dog.
        </a>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <a href="style-guide/style-guide-typography#" className="link dark:link-dark text-base">
          The quick brown fox jumps over the lazy dog.
        </a>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <a href="style-guide/style-guide-typography#" className="text-base-medium link dark:link-dark">
          The quick brown fox jumps over the lazy dog.
        </a>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <a href="style-guide/style-guide-typography#" className="text-base-bold link dark:link-dark">
          The quick brown fox jumps over the lazy dog.
        </a>
      </div>
    </div>

    <div className="text-xs-bold mt-3 pl-3">Text-base</div>
    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="mx-3 w-[20%] text-center">
        <p className="text-base-light">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-base">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-base-medium">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-base-bold">The quick brown fox jumps over the lazy dog.</p>
      </div>
    </div>

    <div className="text-xs-bold mt-3 pl-3">Text-sm</div>
    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="mx-3 w-[20%] text-center">
        <p className="text-sm-light">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-sm">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-sm-medium">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-sm-bold">The quick brown fox jumps over the lazy dog.</p>
      </div>
    </div>

    <div className="text-xs-bold mt-3 pl-3">Text-xs</div>
    <div className="my-3 flex items-center justify-between p-4 align-middle">
      <div className="mx-3 w-[20%] text-center">
        <p className="text-xs-light">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-xs">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-xs-medium">The quick brown fox jumps over the lazy dog.</p>
      </div>
      <div className="mx-3 w-[20%] text-center">
        <p className="text-xs-bold">The quick brown fox jumps over the lazy dog.</p>
      </div>
    </div>
  </div>;
export default StyleGuideTypography;