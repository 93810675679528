import { Image, SectionLayout } from '@engbers/components';
const EngbersImageTastic = ({
  data
}) => {
  if (data.hasMargin) {
    return <SectionLayout>
        <Image media={data.image.media} link={data.link} />
      </SectionLayout>;
  } else {
    return <Image media={data.image.media} link={data.link} />;
  }
};
export default EngbersImageTastic;