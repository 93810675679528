import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { Typography, AccordionItem, SectionLayout, LoginForm } from '@engbers/components';
interface ILoginFormData {
  emailInputPlaceholder?: string;
  emailTooltipContent?: string;
  emailTooltipHeadline?: string;
  firstTimeInShopLabel?: string;
  homepageRedirectLink?: IFTLinkReference | IFTPageFolderReference;
  loginSectionTitle?: string;
  passwordForgotLinkLabel?: string;
  passwordForgottenTooltipContent?: string;
  passwordForgottenTooltipHeadline?: string;
  passwordInputPlaceholder?: string;
  passwordTooltipContent?: string;
  passwordTooltipHeadline?: string;
  loginButtonLabel?: string;
  redirectLink?: IFTLinkReference | IFTPageFolderReference;
  loginInitiallyOpen?: boolean;
}
interface ILoginFormTasticComponent {
  data: ILoginFormData;
}
export const LoginFormTastic: React.FC<ILoginFormTasticComponent> = ({
  data
}) => {
  return <SectionLayout data-sentry-element="SectionLayout" data-sentry-component="LoginFormTastic" data-sentry-source-file="index.tsx">
      <AccordionItem isInitiallyOpen={data.loginInitiallyOpen} label={data.loginSectionTitle || 'Anmelden | Mein Konto'} data-sentry-element="AccordionItem" data-sentry-source-file="index.tsx">
        <LoginForm labels={data} homepageRedirectLink={data.homepageRedirectLink} data-sentry-element="LoginForm" data-sentry-source-file="index.tsx" />
        {data.firstTimeInShopLabel && <Typography>{data.firstTimeInShopLabel}</Typography>}
      </AccordionItem>
    </SectionLayout>;
};