import { Card, SectionLayout } from '@engbers/components';
import { useCardData } from '@frontastic-engbers/lib/actions/account/useCardData';
const EngbersCardTastic = ({
  data
}) => {
  const {
    loaded,
    cardData
  } = useCardData();
  if (!loaded) {
    return null;
  }
  return <SectionLayout data-sentry-element="SectionLayout" data-sentry-component="EngbersCardTastic" data-sentry-source-file="index.tsx">
      <Card custNr={cardData.cardId} barcodeSvg={cardData.barcodeImage.svg} label={data.label} background={data.backgroundImage} data-sentry-element="Card" data-sentry-source-file="index.tsx" />
    </SectionLayout>;
};
export default EngbersCardTastic;