import React from 'react';
const StyleGuideColors: React.FC = () => <div className="dark:bg-encom-900 mb-10 border border-solid border-slate-300 dark:text-white" data-sentry-component="StyleGuideColors" data-sentry-source-file="style-guide-colors.tsx">
    <h2 className="mx-6 my-4 dark:text-white">Colors</h2>
    <div className="my-3 flex flex-wrap justify-start border-t border-solid border-slate-300 p-6 align-middle">
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="h-[60px] w-[100%] rounded border border-solid border-slate-300 bg-white"></div>
        <span className="pt-3 text-xs">--white | #FFFFFF</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="h-[60px] w-[100%] rounded bg-black"></div>
        <span className="pt-3 text-xs">--black | #000000</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="h-[60px] w-[100%] rounded bg-success"></div>
        <span className="pt-3 text-xs">--success | #10b981</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="h-[60px] w-[100%] rounded bg-info"></div>
        <span className="pt-3 text-xs">--info | #009BCC</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="h-[60px] w-[100%] rounded bg-warning"></div>
        <span className="pt-3 text-xs">--warning | #fbbf24</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="h-[60px] w-[100%] rounded bg-danger"></div>
        <span className="pt-3 text-xs">--danger | #f43f5e</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="bg-btn-icon-hover h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--btn-icon-hover | #699CC9</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="bg-link-hover h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--link-hover | #23527C</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="bg-encom-50 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--encom-50 | #F0F2F2</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="bg-encom-100 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--encom-100 | #E1E4E5</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="bg-encom-200 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--encom-200 | #C3C8CB</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="bg-encom-300 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--encom-300 | #A5ACB1</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="bg-encom-400 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--encom-400 | #879097</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="bg-encom-500 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--encom-500 | #69747D</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="bg-encom-600 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--encom-600 | #4B5863</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="bg-encom-700 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--encom-700 | #3C4A56</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="bg-encom-800 h-[60px] w-[100%] rounded"></div>
        <span className="pt-3 text-xs">--encom-800 | #2D3C49</span>
      </div>
      <div className="m-3 flex flex-col items-center" style={{
      width: '220px'
    }}>
        <div className="bg-encom-900 h-[60px] w-[100%] rounded border border-solid dark:border-slate-300"></div>
        <span className="pt-3 text-xs">--encom-900 | #0F202F</span>
      </div>
    </div>
  </div>;
export default StyleGuideColors;