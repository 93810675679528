import { NewsletterSignupItems } from '@engbers/components/my-account/my-newsletter/mobileNewsletter/newsletter-signup-items';
import { SectionLayout } from '@engbers/components';
import { NewsletterConfig } from '@frontastic-engbers/types/newsletter/types';
export const NewsletterItemsTasticNew = ({
  data
}) => {
  return <SectionLayout data-sentry-element="SectionLayout" data-sentry-component="NewsletterItemsTasticNew" data-sentry-source-file="index.tsx">
      <NewsletterSignupItems newsletters={data.newsletter.dataSource as NewsletterConfig[] ?? []} labels={{
      headline: data.headline,
      textSize: data.textSize,
      subscribeRadioLabel: data.subscribeRadioLabel,
      modalFrequencyDescription: data.modalFrequencyDescription,
      modalFrequencyHeadline: data.modalFrequencyHeadline,
      modalFrequencyButtonConfirmLabel: data.modalFrequencyButtonConfirmLabel,
      modalFrequencyButtonCancelLabel: data.modalFrequencyButtonCancelLabel,
      modalFrequencyButtonWeeklyLabel: data.modalFrequencyButtonWeeklyLabel,
      modalFrequencyButtonMonthlyLabel: data.modalFrequencyButtonMonthlyLabel,
      modalFrequencyButtonUnsubscribeLabel: data.modalFrequencyButtonUnsubscribeLabel,
      modalApproveHeadline: data.modalApproveHeadline,
      modalApproveDescription: data.modalApproveDescription,
      modalApproveButtonLabel: data.modalApproveButtonLabel,
      subscriptionSuccessMessage: data.myNewsletterSubscriptionSuccessMessage,
      subscriptionFailureMessage: data.myNewsletterSubscriptionFailureMessage
    }} data-sentry-element="NewsletterSignupItems" data-sentry-source-file="index.tsx" />
    </SectionLayout>;
};