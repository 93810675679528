import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { validateDateFormat } from '@frontastic-engbers/helpers/utils/validateDateFormat';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { IconCustom, InputTooltip, Markdown } from '@engbers/components';
import styles from './input-date.module.scss';
import ReactMarkdown from 'react-markdown';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
type ValidationErrors = {
  format: boolean;
  required: boolean;
};
interface IInputDate {
  birthdayDay?: string | number;
  birthdayMonth?: string | number;
  birthdayYear?: string | number;
  onChange: (value: any) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disableValidatioOnBlur?: boolean;
  label?: string;
  errorMessage?: string;
  required?: boolean;
  isFormatValid?: (valid: boolean) => void;
  stillHasError?: boolean;
  useInfoModal?: boolean;
  info?: string;
}
export const InputDate: React.FC<IInputDate> = ({
  birthdayDay,
  birthdayMonth,
  birthdayYear,
  onChange,
  onBlur,
  disableValidatioOnBlur = false,
  label = '',
  errorMessage,
  // Don't use the errorMessage for empty inputs or date format errors.
  required = false,
  isFormatValid,
  stillHasError = false,
  useInfoModal = true,
  info
}) => {
  const [selectedDate, setSelectedDate] = useState<{
    day: string;
    month: string;
    year: string;
  }>(null);
  const [errors, setErrors] = useState<ValidationErrors | null>(null);
  const [shouldValidate, setShouldValidate] = useState<boolean>(false);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const {
    pushModal
  } = useModalActions();
  const {
    t
  } = useI18n();
  const focusRefs = {
    day: useRef<HTMLInputElement>(),
    month: useRef<HTMLInputElement>()
  };
  const information = typeof info === 'object' ? t(info) : info;
  useEffect(() => {
    if (stillHasError) {
      checkFormat();
    }
  }, [stillHasError]);
  useEffect(() => {
    setSelectedDate({
      day: birthdayDay?.toString(),
      month: birthdayMonth?.toString(),
      year: birthdayYear?.toString()
    });
  }, [birthdayDay, birthdayMonth, birthdayYear]);
  const createModal = () => {
    pushModal({
      title: '',
      id: 'birthdate_info',
      content: <Markdown text={information} className={styles.birthdateInfoMarkdown} />,
      canCloseByBackdropClick: true,
      isLarge: true
    });
  };
  const checkFormat = () => {
    const day = selectedDate?.day;
    const month = selectedDate?.month;
    const year = selectedDate?.year;
    const allFieldsFilled = !!day?.length && !!month?.length && !!year?.length;
    setErrors({
      format: allFieldsFilled ? validateDateFormat(day, month, year) : true,
      required: required ? allFieldsFilled : true
    });
    if (isFormatValid) {
      const isValid = required ? allFieldsFilled && validateDateFormat(day, month, year) : validateDateFormat(day, month, year);
      isFormatValid(isValid);
    }
  };
  const onChangeInput = useCallback((name: string, maxLength: number, e) => {
    const value = parseFloat(e.target.value) > parseFloat(e.target.max) ? e.target.max : e.target.value;
    if (value.length <= maxLength) {
      setSelectedDate(state => ({
        ...state,
        [name]: value
      }));
      onChange({
        [e.target.name]: value
      });
    }
    if (typeof e.nativeEvent.inputType !== 'undefined' && value.length === maxLength) {
      focusRefs[name]?.current?.focus();
      focusRefs[name]?.current?.select();
    }
  }, [onChange]);
  const onBlurInput = useCallback((name: string, e) => {
    setSelectedDate(state => {
      onChange({
        [e.target.name]: state[name] && state[name].padStart(2, '0')
      });
      return {
        ...state,
        day: state.day && state.day.padStart(2, '0'),
        month: state.month && state.month.padStart(2, '0')
      };
    });
    !disableValidatioOnBlur && setShouldValidate(true);
    onBlur?.(e);
  }, [onChange, onBlur]);
  useEffect(() => {
    if (shouldValidate) {
      setShouldValidate(false);
      checkFormat();
    }
  }, [shouldValidate]);
  return <div data-sentry-component="InputDate" data-sentry-source-file="index.tsx">
      <div className={styles.outsideRow}>
        {label && <div className={styles.label}>
            {label} {required && '*'}
          </div>}
        <div className={styles.row}>
          <input id="birthday_day" name="birthdayDay" maxLength={2} min={1} max={31} type="number" autoComplete="" required placeholder={formatMessage({
          id: 'date.format.dd'
        })} value={selectedDate?.day || ''} onChange={onChangeInput.bind(null, 'day', 2)} onBlur={onBlurInput.bind(null, 'day')} className={!label ? styles.customInput : ''} />
          <div className={label ? styles.inputBorder : styles.inputBorderSecond}>
            <input id="birthday_month" maxLength={2} min={1} max={12} name="birthdayMonth" type="number" autoComplete="" required placeholder={formatMessage({
            id: 'date.format.mm'
          })} value={selectedDate?.month || ''} onChange={onChangeInput.bind(null, 'month', 2)} onBlur={onBlurInput.bind(null, 'month')} ref={focusRefs.day} className={!label ? styles.customInput : ''} />
          </div>
          <input id="birthday_year" maxLength={4} min={new Date().getFullYear() - 100} max={new Date().getFullYear()} name="birthdayYear" type="number" autoComplete="" required placeholder={formatMessage({
          id: 'date.format.yyyy'
        })} value={selectedDate?.year || ''} onChange={onChangeInput.bind(null, 'year', 4)} onBlur={onBlurInput.bind(null, 'year')} ref={focusRefs.month} className={!label ? styles.customInput : ''} />
          {information && <div className={styles.infoIconWrap} onClick={e => {
          e.preventDefault();
          if (useInfoModal) {
            createModal();
          } else {
            setOpenTooltip(!openTooltip);
          }
        }}>
              <IconCustom icon="Info" width={22} />
              <InputTooltip onClose={() => {
            setOpenTooltip(false);
          }} message={<div className={styles.modalContent}>
                    <ReactMarkdown>{information}</ReactMarkdown>
                  </div>} isActive={openTooltip} />
            </div>}
        </div>
      </div>
      {errors && !errors.required && <div className={styles.errorMessage}>
          {formatMessage({
        id: 'birthDate'
      }) + formatErrorMessage({
        id: 'mandatory.error'
      })}
        </div>}
      {errors && !errors.format && <div className={styles.errorMessage}>
          {formatErrorMessage({
        id: 'dateFormat',
        defaultMessage: 'Dieses Geburtsdatum ist nicht gültig!'
      })}
        </div>}
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>;
};