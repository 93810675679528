import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { HeaderMain } from '@engbers/components/header-main';
import { HeaderMinimized } from '@engbers/components/header-minimized';
import { HeaderServiceArea } from '@engbers/components/header-service-area';
import { useCurrentShop } from '@frontastic-engbers/lib';
export const HeaderTastic = ({
  data
}) => {
  let isCheckout = false;
  const currentSection = useCurrentShop();
  const isEnGermany = currentSection === "engbers-germany";
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  useEffect(() => {
    isCheckout = window.location.pathname.includes('checkout') || window.location.href.includes('checkout');
  }, []);
  return <>
      {isCheckout || isMinWidthMedium ? <HeaderMinimized mobileCheckoutLogo={data.mobileCheckoutLogo} mobileCheckoutLogoLink={data.mobileCheckoutLogoLink} trustedShopIcon={data.trustedShopIcon} trustedShopText={data.trustedShopText} isCheckout={isCheckout} isEnGermany={isEnGermany} /> : <>
          <HeaderServiceArea services={data.services} />
          <HeaderMain logos={data.logos} items={data.items} />
        </>}
    </>;
};