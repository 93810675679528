import React, { useCallback, useEffect, useState } from 'react';
import { Block, Button, Typography } from '@engbers/components';
import { ContactFormBody, ContactFormResponse } from '../../../../backend/engbers/types';
import { fetchApiHub } from '@frontastic-engbers/lib';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { InputText } from '@engbers/components/shopmacher-ui/input-text';
import { Textarea } from '@engbers/components/textarea';
import styles from './contact-form.module.scss';
import { useCardData } from '@frontastic-engbers/lib/actions/account/useCardData';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
export interface IContactForm {
  headline: string;
}
type FormData = {
  email: string;
  firstName: string;
  lastName: string;
  custNo: string | number;
  message: string;
};
type ValidationErrors = {
  generalError?: string;
  email: boolean;
  firstName: boolean;
  lastName: boolean;
  custNo: boolean;
  message: boolean;
};
export const ContactForm: React.FC<IContactForm> = ({
  headline
}) => {
  const {
    cardData
  } = useCardData();
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    formatMessage: formatSuccessMessage
  } = useFormat({
    name: 'success'
  });
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const [errors, setErrors] = useState<Partial<ValidationErrors>>({});
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [initialSend, setInitialSend] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    email: '',
    firstName: '',
    lastName: '',
    custNo: '',
    message: ''
  });
  useEffect(() => {
    if (cardData) {
      setFormData(prevData => {
        return {
          ...prevData,
          custNo: cardData.cardId
        };
      });
    }
  }, [cardData?.cardId]);
  const resetFormData = useCallback(() => {
    setFormData(() => {
      return {
        email: '',
        firstName: '',
        lastName: '',
        message: '',
        custNo: cardData.cardId
      };
    });
  }, [cardData?.cardId]);
  const {
    pushNotification
  } = useToastNotificationsActions();
  const submitContactForm = async (body: ContactFormBody): Promise<ContactFormResponse> => {
    return fetchApiHub('/action/engbers/submitContactForm', {
      method: 'POST'
    }, body);
  };
  const validate = () => {
    const errors: ValidationErrors = {
      firstName: formData.firstName !== '',
      lastName: formData.lastName !== '',
      email: formData.email !== '',
      custNo: formData.custNo !== null,
      message: formData.message !== ''
    };
    setErrors(errors);
    return !Object.values(errors).includes(false);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setInitialSend(true);
    if (!validate()) {
      return;
    }
    setLoading(true);
    const response = await submitContactForm(formData);
    if (!response.success) {
      setErrors({
        generalError: formatErrorMessage({
          id: 'wentWrong',
          defaultMessage: 'Sorry. Something went wrong..'
        })
      });
      pushNotification(formatErrorMessage({
        id: 'failedMsgSend'
      }), 'error');
      resetFormData();
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        generalError: null
      }));
      resetFormData();
      pushNotification(formatSuccessMessage({
        id: 'SendMsg'
      }), 'success');
    }
    setLoading(false);
  };
  return <>
      <h4 className={styles.headline}>{headline}</h4>

      <form onSubmit={handleSubmit}>
        <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <Typography color="danger" size="base" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {errors.generalError ?? null}
          </Typography>
          <Typography color="success" size="base" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {success ?? null}
          </Typography>
        </Block>
        <Block marginBottom={8} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
            <label className="sr-only" htmlFor="firstName">
              {formatMessage({
              id: 'firstName',
              defaultMessage: 'Forename'
            })}
            </label>
            <InputText style={'grey'} id="firstName" errorMessage={!errors.firstName && initialSend && formatErrorMessage({
            id: 'fillInput'
          })} name="firstName" type="text" onChange={handleChange} placeholder={formatMessage({
            id: 'firstName',
            defaultMessage: 'Forename'
          })} required value={formData.firstName} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
            <label className="sr-only" htmlFor="lastName">
              {formatMessage({
              id: 'lastName',
              defaultMessage: 'Surname'
            })}
            </label>
            <InputText style={'grey'} errorMessage={!errors.lastName && initialSend && formatErrorMessage({
            id: 'fillInput'
          })} id="lastName" name="lastName" type="text" onChange={handleChange} placeholder={formatMessage({
            id: 'lastName',
            defaultMessage: 'Surname'
          })} required value={formData.lastName} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
            <label className="sr-only" htmlFor="email">
              {formatMessage({
              id: 'email',
              defaultMessage: 'Email'
            })}
            </label>
            <InputText style={'grey'} id="email" errorMessage={!errors.email && initialSend && formatErrorMessage({
            id: 'fillInput'
          })} name="email" type="email" onChange={handleChange} placeholder={formatMessage({
            id: 'email',
            defaultMessage: 'Email'
          })} required value={formData.email} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
            <label className="sr-only" htmlFor="custNo">
              {formatMessage({
              id: 'custNo',
              defaultMessage: 'Customer No.'
            })}
            </label>
            <InputText style={'grey'} id="custNo" name="custNo" type="number" errorMessage={!errors.custNo && initialSend && formatErrorMessage({
            id: 'fillInput'
          })} onChange={handleChange} placeholder={formatMessage({
            id: 'custNo',
            defaultMessage: 'Customer No.'
          })} required value={`${formData.custNo}`} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
            <label className="sr-only" htmlFor="message">
              {formatMessage({
              id: 'message',
              defaultMessage: 'Your enquiry'
            })}
            </label>
            <Textarea style={'grey'} id="message" name="message" errorMessage={!errors.message && initialSend && formatErrorMessage({
            id: 'fillInput'
          })} onChange={handleChange} placeholder={formatMessage({
            id: 'message',
            defaultMessage: 'Your enquiry'
          })} rows={5} required value={formData.message} data-sentry-element="Textarea" data-sentry-source-file="index.tsx" />
          </Block>
        </Block>

        <Button onClick={handleSubmit} isLoading={loading} size="large" label={formatMessage({
        id: 'submit',
        defaultMessage: 'Submit'
      })} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
      </form>
    </>;
};