import classNames from 'classnames';
import { IconCustom } from '@engbers/components';
import styles from './header-login.module.scss';
interface IHeaderLogin {
  headline: string;
  subHeadline: string;
}
export const HeaderLogin: React.FC<IHeaderLogin> = ({
  headline,
  subHeadline
}) => {
  return <header className={styles.wrap} data-sentry-component="HeaderLogin" data-sentry-source-file="index.tsx">
      <div className={styles.logoWrap}>
        <IconCustom width={60} icon="Logo" color="white" data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
      </div>
      <div className={classNames(styles.titleWrap)}>
        <div className={styles.headline}>{headline}</div>
        <div className={styles.subHeadline}>{subHeadline}</div>
      </div>
    </header>;
};