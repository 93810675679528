import React from 'react';
import classNames from 'classnames';
import { IconCustom, TIconId } from '@engbers/components/icon-custom';
import styles from './navigation-bottom-item.module.scss';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
interface INavigationItem {
  id: string;
  label: string;
  icon: TIconId;
  isActive?: boolean;
  reference?: IFTPageFolderReference | IFTLinkReference;
  counter?: number;
}
const CUSTOM_SETTINGS: { [key in any]: {
  width: number;
  bottom: string | number;
} } = {
  Logo: {
    width: 50,
    bottom: '-3px'
  },
  UserCrown: {
    width: undefined,
    bottom: 0
  },
  ShoppingBag: {
    width: 44,
    bottom: '-2px'
  },
  Inbox: {
    width: 24,
    bottom: -1
  },
  MapMarkerPwa: {
    width: 36,
    bottom: '-1px'
  }
};
export const NavigationBottomItem: React.FC<INavigationItem> = ({
  id,
  label,
  icon,
  isActive,
  reference,
  counter
}) => {
  return <li className={classNames(styles.navigationItem, {
    [styles[`navigationItem--active`]]: isActive
  })} key={`navigation-bottom-${id}`} data-sentry-component="NavigationBottomItem" data-sentry-source-file="index.tsx">
      <ReferenceLink className={styles.linkWrap} target={reference} data-sentry-element="ReferenceLink" data-sentry-source-file="index.tsx">
        <div className={styles.iconWrap} style={{
        bottom: CUSTOM_SETTINGS[icon]?.bottom
      }}>
          {icon && <div className={styles.counterWrap}>
              {counter ? <div className={styles.counter}>{counter}</div> : undefined}
              <IconCustom width={CUSTOM_SETTINGS[icon]?.width} color={isActive ? 'green-neon' : 'darkBlue'} icon={icon} />
            </div>}
        </div>

        <div className={styles.label}>{label}</div>
      </ReferenceLink>
    </li>;
};