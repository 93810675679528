import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useState as useGlobalState } from 'state-pool';
import classnames from 'classnames';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { GlobalStateContext, useAccount, useCart, useCurrentShop, useWishlist } from '@frontastic-engbers/lib';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { IFTLinkReference, IFTPageFolderReference, MediaType } from '@frontastic-engbers/types/engbers-custom';
import { ServiceType } from '../header-service-area';
import { HeaderServiceArea } from '@engbers/components/header-service-area';
import { Image } from '@engbers/components';
import styles from './header-main.module.scss';
export interface HeaderMainType {
  logos: Logo[];
  items: Item[];
  isEmilio?: boolean;
  services?: ServiceType[];
}
interface Logo {
  logo: MediaType;
  logoLink: IFTLinkReference | IFTPageFolderReference;
  relativePath: string;
  activeShop: 'engbers' | 'engbers-xxl' | 'engbers-germany' | 'emilio-adani';
}
interface Item {
  tabType: 'isMyAccount' | 'isWishlist' | 'isBasket';
  showLogoutBtnInHeader: boolean;
  itemIcon: MediaType;
  itemLabel: string;
  itemLink: IFTLinkReference | IFTPageFolderReference;
}
interface Amount {
  amount: number | null;
}
export const HeaderMain: React.FC<HeaderMainType> = ({
  logos,
  items,
  isEmilio = false,
  services
}) => {
  const {
    getTitle
  } = useImageSEO();
  const {
    data: cart
  } = useCart();
  const {
    data: wishlist
  } = useWishlist();
  const {
    asPath
  } = useRouter();
  const currentSection = useCurrentShop();
  const getLineItemsCount = (): number => cart.lineItems.reduce((accumulator, lineItem) => accumulator + lineItem.count, 0);
  const getHeaderItemAmount = (itemType: string): number | null => {
    switch (itemType) {
      case 'isBasket':
        return cart?.lineItems ? getLineItemsCount() : null;
      case 'isWishlist':
        return wishlist?.lineItems?.length || null;
      default:
        return null;
    }
  };
  return <div className={classnames(styles.HeaderMainWrap, {
    [styles.emilioHeaderMainWrap]: isEmilio,
    [styles.enGermanyHeaderMainWrap]: currentSection === 'engbers-germany'
  })} data-sentry-component="HeaderMain" data-sentry-source-file="index.tsx">
      <div className={classnames(styles.HeaderMain, {
      [styles.emilioHeaderMain]: isEmilio,
      [styles.enGermanyHeaderMain]: currentSection === 'engbers-germany'
    })}>
        {isEmilio && <div className={styles.emilioHeaderServiceWrap}>
            <HeaderServiceArea services={services} isFullWidth={false} isEmilio={isEmilio} />
          </div>}
        <div className={styles.logosWrapper}>
          {logos.map((logo, index) => {
          const isEmilioAdaniNotSelected = logo.activeShop === 'emilio-adani' && !asPath.includes(logo.relativePath);
          if (logo.logo.media.format !== 'svg') {
            return null;
          }
          return <ReferenceLink key={`logo-link-${index}`} target={logo.logoLink}>
                <img src={logo.logo.media.file} alt={getTitle(logo.logo)} title={getTitle(logo.logo)} className={classnames({
              [styles['isActive']]: currentSection === logo.activeShop,
              [styles['emilioAdaniNotSelected']]: isEmilioAdaniNotSelected
            })} style={logo.activeShop === 'emilio-adani' ? {
              marginBottom: '-14px'
            } : logo.activeShop === 'engbers' ? {
              marginBottom: '-8px'
            } : undefined} />
              </ReferenceLink>;
        })}
        </div>
        <div className={styles.itemsWrapper}>
          {items.map((item, index) => <HeaderItem key={`header-item-${index}`} tabType={item.tabType} showLogoutBtnInHeader={item.showLogoutBtnInHeader} itemIcon={item.itemIcon} itemLabel={item.itemLabel} itemLink={item.itemLink} amount={getHeaderItemAmount(item.tabType)} />)}
        </div>
      </div>
    </div>;
};
const HeaderItem: React.FC<Item & Amount> = ({
  tabType,
  showLogoutBtnInHeader,
  itemIcon,
  itemLink,
  itemLabel,
  amount = null
}) => {
  const {
    getTitle
  } = useImageSEO();
  const {
    logout,
    loggedIn
  } = useAccount();
  const router = useRouter();
  const {
    formatMessage
  } = useFormat({
    name: 'account'
  });
  const currentSection = useCurrentShop();
  const handleLogout = useCallback(async () => {
    await logout();
    return router.push('/login');
  }, [logout, router]);
  return <div className={classnames(styles.itemLinksWrapper, {
    [styles.enGermanyItemLinksWrapper]: currentSection === 'engbers-germany'
  })} data-sentry-component="HeaderItem" data-sentry-source-file="index.tsx">
      <HeaderItemWrapper type={tabType} link={itemLink} data-sentry-element="HeaderItemWrapper" data-sentry-source-file="index.tsx">
        {itemIcon.media.format === 'svg' && <Image src={itemIcon.media.file} alt={getTitle(itemIcon)} title={getTitle(itemIcon)} />}
        <span>{itemLabel}</span>
        {amount && amount > 0 ? <span className={classnames(styles.amount, {
        [styles.wishlistAmount]: tabType === 'isWishlist',
        [styles.enGermanyAmount]: currentSection === 'engbers-germany'
      })} style={amount >= 100 ? {
        width: '22px',
        height: '22px',
        fontSize: '10px'
      } : undefined}>
            {amount >= 100 ? '+99' : amount}
          </span> : null}
      </HeaderItemWrapper>
      {tabType === 'isMyAccount' && showLogoutBtnInHeader && loggedIn ? <button className={styles.logoutButton} onClick={handleLogout}>
          {formatMessage({
        id: 'signout',
        defaultMessage: 'abmelden'
      })}
        </button> : null}
    </div>;
};
const HeaderItemWrapper: React.FC<{
  type: 'isMyAccount' | 'isWishlist' | 'isBasket';
  link: IFTLinkReference | IFTPageFolderReference;
}> = ({
  type,
  link,
  children
}) => {
  const router = useRouter();
  const [{
    isFlyoutCartOpen
  }, setGlobalState] = useGlobalState(GlobalStateContext);
  if (type === 'isBasket') {
    return <button className="basketButton" onClick={() => setGlobalState({
      isFlyoutCartOpen: router?.asPath?.includes('/cart') ? false : !isFlyoutCartOpen
    })}>
        {children}
      </button>;
  }
  return <ReferenceLink target={link} data-sentry-element="ReferenceLink" data-sentry-component="HeaderItemWrapper" data-sentry-source-file="index.tsx">{children}</ReferenceLink>;
};