import { NewsletterSignupItems } from '../../../../components/engbers-specific/newsletter-signup-items';
import { SectionLayout } from '@engbers/components';
export const NewsletterItemsTastic = ({
  data
}) => {
  return <SectionLayout data-sentry-element="SectionLayout" data-sentry-component="NewsletterItemsTastic" data-sentry-source-file="index.tsx">
      <NewsletterSignupItems labels={{
      headline: data.headline,
      subscribeRadioLabel: data.subscribeRadioLabel,
      modalFrequencyDescription: data.modalFrequencyDescription,
      modalFrequencyHeadline: data.modalFrequencyHeadline,
      modalFrequencyButtonConfirmLabel: data.modalFrequencyButtonConfirmLabel,
      modalFrequencyButtonCancelLabel: data.modalFrequencyButtonCancelLabel,
      modalFrequencyButtonWeeklyLabel: data.modalFrequencyButtonWeeklyLabel,
      modalFrequencyButtonMonthlyLabel: data.modalFrequencyButtonMonthlyLabel,
      modalFrequencyButtonUnsubscribeLabel: data.modalFrequencyButtonUnsubscribeLabel,
      modalApproveHeadline: data.modalApproveHeadline,
      modalApproveDescription: data.modalApproveDescription,
      modalApproveButtonLabel: data.modalApproveButtonLabel
    }} items={data.items} data-sentry-element="NewsletterSignupItems" data-sentry-source-file="index.tsx" />
    </SectionLayout>;
};