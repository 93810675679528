import { useRecoilValueLoadable } from 'recoil';
import { TabMenu } from '../../../../components/engbers-specific/tab-menu';
import { unreadMessageCount, unreadNewsCount } from 'state/messages/atoms';
const EngbersTabMenuTastic = ({
  data
}) => {
  const unreadMessageC = useRecoilValueLoadable(unreadMessageCount);
  const unreadNewsC = useRecoilValueLoadable(unreadNewsCount);
  return <TabMenu items={data.tabItems.map(item => {
    let count;
    if (item.messageCountType === 'messages') {
      count = unreadMessageC.state === 'hasValue' ? unreadMessageC.contents : undefined;
    } else if (item.messageCountType === 'news') {
      count = unreadNewsC.state === 'hasValue' ? unreadNewsC.contents : undefined;
    }
    return {
      label: item.label,
      counter: count,
      reference: item.reference
    };
  })} data-sentry-element="TabMenu" data-sentry-component="EngbersTabMenuTastic" data-sentry-source-file="index.tsx" />;
};
export default EngbersTabMenuTastic;