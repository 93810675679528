import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { FrontasticRenderer } from '@frontastic-engbers/lib/lib/renderer';
import { fetchApiHub, LocaleStorage, PageDataResponse, PagePreviewDataResponse } from '@frontastic-engbers/lib';
import { tastics } from '../../../frontastic/tastics';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { mapLanguage } from '@frontastic-engbers/lib/project.config';
import styles from './tab-menu.module.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { LoadingIndicatorInline } from '@engbers/components';
import { TabMenuItem, ITabMenuItem } from './components/tab-menu-item';
export interface ITabMenu {
  items?: ITabMenuItem[];
}
const wrapVariants = {
  initial: {
    opacity: 0,
    x: -20
  },
  animate: {
    opacity: 1,
    x: 0
  },
  exit: {
    opacity: 0,
    x: 20
  }
};
export const TabMenu: React.FC<ITabMenu> = ({
  items
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const router = useRouter();
  const [tabData, setTabData] = useState<(PageDataResponse & PagePreviewDataResponse) | false>(false);
  const [active, setActive] = useState(items[0]?.reference?.pageFolderId);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async path => {
      await handleChange(path);
    };
    let path = items[0]?.reference?._url;
    if (router.asPath.includes('#')) {
      path = router.asPath.replace('#', '/');
    }
    fetchData(path);
  }, []);
  const handleChange = async path => {
    setLoading(true);
    setActive(path);
    setTabData(await fetchContent(path));
    setLoading(false);
  };
  const fetchContent = async (path: string) => {
    const data: PageDataResponse & PagePreviewDataResponse = (await fetchApiHub('/page', {
      headers: {
        'Frontastic-Path': path,
        'Frontastic-Locale': mapLanguage(LocaleStorage.locale)
      }
    })) as PageDataResponse & PagePreviewDataResponse;
    if (data && 'page' in data) {
      return data;
    }
    return false;
  };
  return <>
      <ul className={styles.tabs}>
        {items.map(item => {
        const label = item.label !== '' ? item.label : item.reference.name;
        return <TabMenuItem label={label} counter={item.counter} reference={item.reference} isActive={item.reference._url === active} key={`tab-menu-key-${label}`} onClick={handleChange} />;
      })}
      </ul>

      <div className={styles.tabContent}>
        <AnimatePresence exitBeforeEnter data-sentry-element="AnimatePresence" data-sentry-source-file="index.tsx">
          <motion.div key={`${(tabData as any)?.page?.pageId}-${loading && 'loading'}`} variants={wrapVariants} initial="initial" animate={'animate'} exit="exit" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
            {!tabData && !loading ? formatMessage({
            id: 'page.notFound',
            defaultMessage: 'Page not found'
          }) : null}{' '}
            {loading && <div className={styles.loadingIndicator}>
                <LoadingIndicatorInline color="blue" />
              </div>}
            {tabData && !loading ? <FrontasticRenderer data={tabData as any} tastics={tastics} gridClassName="min-h-0" wrapperClassName={`px-0 ${styles[`wrap-tab-modifier`]}`} /> : null}
          </motion.div>
        </AnimatePresence>
      </div>
    </>;
};