import { useCallback } from 'react';
import styles from './newsletter-signup-items.module.scss';
import { NewsletterSignupItemsItem } from './component/newsletter-signup-items-item';
import { GetNewsletterSubscriptionsResponse, NewsletterIdentifier } from '@frontastic-engbers/types/account/Account';
import useSWR, { mutate } from 'swr';
import { fetchApiHub, revalidateOptions } from '@frontastic-engbers/lib';
import { updateNewsletterSubscriptions } from '@frontastic-engbers/lib/actions/account';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { Block, Button, LoadingIndicatorInline, Typography } from '@engbers/components';
import { NewsletterSignupUnsubModal } from '../newsletter-signup-unsub-modal';
const useNewsletterSubscriptions = () => {
  const result = useSWR<GetNewsletterSubscriptionsResponse>('/action/account/getNewsletterSubscriptions', fetchApiHub, revalidateOptions);
  if (!result.data) {
    return {
      loaded: false,
      subscriptions: null
    };
  }
  return {
    loaded: true,
    subscriptions: result.data.subscriptions
  };
};
type NewsletterItemType = 'engbers' | 'engbersXXL' | 'emilio-adani';
type NewsletterSignupItemsProps = {
  items: Array<{
    description: string;
    type: NewsletterItemType;
    logo?: any;
  }>;
  labels: any;
};
const mapNewsletterItemTypeToNewsletterIdentifier = (itemType: NewsletterItemType): null | NewsletterIdentifier => {
  if (itemType === 'engbers') {
    return 'Engbers_NL_Anmeldungen';
  } else if (itemType === 'engbersXXL') {
    return 'XXL_NL_Anmeldungen';
  } else if (itemType === 'emilio-adani') {
    return 'Emilio_NL_Anmeldungen';
  }
  return null;
};
const getAlternativeFrequencyOptions = (newsletterItemType: NewsletterItemType) => {
  const identifier = mapNewsletterItemTypeToNewsletterIdentifier(newsletterItemType);
  if (['Engbers_NL_Anmeldungen', 'Emilio_NL_Anmeldungen'].includes(identifier)) {
    return {
      weekly: `${identifier}_weekly`,
      monthly: `${identifier}_monthly`
    };
  }
  return {};
};
const isNewsletterSubscribed = (itemType: NewsletterItemType, subscriptions: GetNewsletterSubscriptionsResponse['subscriptions']) => {
  const identifier = mapNewsletterItemTypeToNewsletterIdentifier(itemType);
  if (identifier === null) {
    return false;
  }
  const frequencies = Object.keys(getAlternativeFrequencyOptions(itemType));
  const keys = [identifier, ...frequencies.map(f => `${identifier}_${f}`)];
  return keys.some(key => subscriptions[key] === true);
};
export const NewsletterSignupItems = ({
  items,
  labels
}: NewsletterSignupItemsProps) => {
  const {
    loaded,
    subscriptions
  } = useNewsletterSubscriptions();
  const {
    pushModal,
    removeModal
  } = useModalActions();
  const onModalClose = useCallback(async () => {
    removeModal();
    await mutate('/action/account/getNewsletterSubscriptions');
  }, [removeModal]);
  return <div className={styles.itemWrap} data-sentry-component="NewsletterSignupItems" data-sentry-source-file="index.tsx">
      <Block marginTop={8} marginBottom={8} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <Typography weight="semi-bold" size="xl" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          {labels.headline}
        </Typography>
      </Block>
      {!loaded && <LoadingIndicatorInline color="blue" />}
      {loaded && items?.map((item, index) => {
      const newsletterIdentifier = mapNewsletterItemTypeToNewsletterIdentifier(item.type);
      if (newsletterIdentifier === null) {
        return null;
      }
      const isActive = isNewsletterSubscribed(item.type, subscriptions);
      return <div key={`newsletter-items-${index}`} className={styles.item}>
              <NewsletterSignupItemsItem labels={labels} description={item.description} image={item?.logo} isActive={isActive} onChange={() => {
          if (isActive) {
            const frequencyOptions = getAlternativeFrequencyOptions(item.type);
            pushModal({
              title: labels.modalFrequencyHeadline,
              id: 'newsletter-unsubscribe',
              content: <NewsletterSignupUnsubModal labels={labels} identifier={newsletterIdentifier} frequencyOptions={frequencyOptions} onClose={() => removeModal()} updateSettings={settings => {
                updateNewsletterSubscriptions({
                  ...subscriptions,
                  ...settings
                });
              }} />
            });
          } else {
            pushModal({
              title: labels.modalApproveHeadline,
              id: 'newsletter-subscribe',
              onClose: onModalClose,
              content: <div>
                          <div>{labels.modalApproveDescription}</div>
                          <Block marginTop={4}>
                            <Button onClick={onModalClose} size="small" label={labels.modalApproveButtonLabel} />
                          </Block>
                        </div>
            });
            updateNewsletterSubscriptions({
              ...subscriptions,
              [newsletterIdentifier]: true
            });
          }
        }} />
            </div>;
    })}
    </div>;
};