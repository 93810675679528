import { Typography } from '@engbers/components/typography';
import styles from './message.module.scss';
import { Block } from '@engbers/components/block';
export const Message = ({
  headline,
  image,
  description
}) => {
  return <div className={styles.row} data-sentry-component="Message" data-sentry-source-file="index.tsx">
      {image && <div className={styles.imageWrap}>
          <img width={90} src={image} alt={headline} />
        </div>}
      <div className={styles.content}>
        <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <Typography transform="uppercase" weight="bold" size="xl" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {headline}
          </Typography>
        </Block>
        <div>
          <Typography tag="div" size="l" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {description}
          </Typography>
        </div>
      </div>
    </div>;
};