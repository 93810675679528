import { useRecoilValue } from 'recoil';
import { messagesState } from 'state/messages/atoms';
import { Image, SectionLayout } from '@engbers/components';
import { useMessageActions } from 'state/messages/actions';
import { useEffect } from 'react';
export const NewsVisualizer = () => {
  const items = useRecoilValue(messagesState);
  const {
    markAllNewsRead
  } = useMessageActions();
  useEffect(() => {
    markAllNewsRead();
  }, []);
  return <div data-sentry-component="NewsVisualizer" data-sentry-source-file="index.tsx">
      {items.allRealNews.map((newsItem, itemIndex) => {
      return <div key={`item-${itemIndex}`}>
            {newsItem.hasMargin ? <SectionLayout>
                <Image media={newsItem.image.media} link={newsItem.link} />
              </SectionLayout> : <Image media={newsItem.image.media} link={newsItem.link} />}
          </div>;
    })}
    </div>;
};