import { OrderHistory } from '@engbers/components';
export const OrderHistoryTastic = ({
  data
}) => {
  return <div data-sentry-component="OrderHistoryTastic" data-sentry-source-file="index.tsx">
      <OrderHistory orderStatusLabels={{
      1: data.orderStatus1,
      2: data.orderStatus2,
      3: data.orderStatus3,
      4: data.orderStatus4
    }} data-sentry-element="OrderHistory" data-sentry-source-file="index.tsx" />
    </div>;
};