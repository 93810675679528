import React from 'react';
import StyleGuideButtons from '@engbers/engbers-frontend/components/engbers-specific/style-guide/style-guide-buttons';
import StyleGuideGrid from '@engbers/engbers-frontend/components/engbers-specific/style-guide/style-guide-grid';
import StyleGuideIcons from '@engbers/engbers-frontend/components/engbers-specific/style-guide/style-guide-icons';
import StyleGuideInputs from '@engbers/engbers-frontend/components/engbers-specific/style-guide/style-guide-inputs';
import StyleGuideTypography from '@engbers/engbers-frontend/components/engbers-specific/style-guide/style-guide-typography';
import StyleGuideColors from '@engbers/engbers-frontend/components/engbers-specific/style-guide/style-guide-colors';
const StyleGuideTastic: React.FC = () => {
  return <div data-sentry-component="StyleGuideTastic" data-sentry-source-file="index.tsx">
      <StyleGuideColors data-sentry-element="StyleGuideColors" data-sentry-source-file="index.tsx" />
      <StyleGuideTypography data-sentry-element="StyleGuideTypography" data-sentry-source-file="index.tsx" />
      <StyleGuideButtons data-sentry-element="StyleGuideButtons" data-sentry-source-file="index.tsx" />
      <StyleGuideInputs data-sentry-element="StyleGuideInputs" data-sentry-source-file="index.tsx" />
      <StyleGuideIcons data-sentry-element="StyleGuideIcons" data-sentry-source-file="index.tsx" />
      <StyleGuideGrid data-sentry-element="StyleGuideGrid" data-sentry-source-file="index.tsx" />
    </div>;
};
export default StyleGuideTastic;