import { useAccount } from '@frontastic-engbers/lib';
import { MyAccount } from '@engbers/components/my-account';
export const MyAccountTastic = ({
  data
}) => {
  const {
    loggedIn
  } = useAccount();
  if (!loggedIn) {
    return null;
  }
  return <MyAccount title={data.title} navPoints={data.navPoints} data-sentry-element="MyAccount" data-sentry-component="MyAccountTastic" data-sentry-source-file="index.tsx" />;
};