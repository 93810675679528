import { Button, SectionLayout } from '@engbers/components';
export const EngbersButtonTastic = ({
  data
}) => {
  //data.algin = "left" | "center" | "right"
  //data.size = "default" | "large" | "small"
  const alignMapping = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };
  return <SectionLayout data-sentry-element="SectionLayout" data-sentry-component="EngbersButtonTastic" data-sentry-source-file="index.tsx">
      <div style={{
      display: 'flex',
      justifyContent: alignMapping[data.align] || 'flex-start'
    }}>
        <div style={{
        width: `${data.width}%`
      }}>
          <Button customIconURL={data.buttonIcon?.media?.file} label={data.label} customIconWidth={data.iconWidth} type={data.type} size={data.size} color={data.color || undefined} textColor={data.textColor} link={data.link} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
        </div>
      </div>
    </SectionLayout>;
};