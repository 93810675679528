import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { FormattedAmount, IconCustom } from '@engbers/components';
import styles from './order-item-accordion.module.scss';
type OrderItemAccordionProps = React.PropsWithChildren<{
  headline: string;
  subHeadline: string;
  isInitiallyOpen?: boolean;
  hasShiftedContent?: boolean;
  invoiceAmount?: number;
  colors?: {
    [key: string]: string;
  };
}>;
export const OrderItemAccordion = ({
  children,
  headline,
  subHeadline,
  isInitiallyOpen,
  hasShiftedContent,
  invoiceAmount,
  colors
}: OrderItemAccordionProps) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen || false);
  const toggleOpen = useCallback(() => {
    setIsOpen(prevState => !prevState);
  }, []);
  const onClickContent = useCallback(event => {
    event.stopPropagation();
    return false;
  }, []);
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  return <div onClick={toggleOpen} className={classNames(styles.wrap, {
    [styles.wrapIsOpen]: isOpen
  })} data-sentry-component="OrderItemAccordion" data-sentry-source-file="index.tsx">
      <header className={styles.header} style={{
      backgroundColor: colors ? colors.accordeonColor : undefined,
      color: colors ? colors.accordeonTextColor : undefined
    }}>
        <div>
          <div className={styles.headline}>{headline}</div>
          <div className={styles.subHeadline}>{subHeadline}</div>
        </div>
        {!isMinWidthMedium && invoiceAmount ? <div className={styles.amount}>
            <FormattedAmount amount={invoiceAmount} />
          </div> : null}
        <div className={classNames(styles.openButton)}>
          <IconCustom className={styles.chevronIcon} width={isMinWidthMedium ? 16 : 32} color="primary" icon="ChevronRightIcon" data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
        </div>
      </header>

      {isOpen && <div onClick={onClickContent} className={classNames(styles.content, {
      [styles.hasShiftedContent]: hasShiftedContent
    })} style={{
      backgroundColor: colors ? colors.accordeonContentColor : undefined,
      color: colors ? colors.accordeonContentTextColor : undefined
    }}>
          {children}
        </div>}
    </div>;
};