import React from 'react';
import classnames from 'classnames';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { IFTLinkReference, IFTPageFolderReference, MediaType } from '@frontastic-engbers/types/engbers-custom';
import { Image } from '@engbers/components';
import styles from './header-service-area.module.scss';
export interface HeaderServiceAreaType {
  services: ServiceType[];
  isFullWidth?: boolean;
  isEmilio?: boolean;
}
export interface ServiceType {
  service: string;
  serviceLink: IFTLinkReference | IFTPageFolderReference;
  serviceIcon?: MediaType;
}
export const HeaderServiceArea: React.FC<HeaderServiceAreaType> = ({
  services,
  isFullWidth = false,
  isEmilio = false
}) => {
  const {
    getTitle
  } = useImageSEO();
  return <div className={isFullWidth ? styles.headerServiceAreaWrap : undefined} data-sentry-component="HeaderServiceArea" data-sentry-source-file="index.tsx">
      <ul className={classnames(styles.headerServiceArea, {
      [styles.emilioHeaderServiceArea]: isEmilio
    })}>
        {services.map((service, index) => <li key={`header-service-${index}`} className={styles.headerService}>
            <ReferenceLink className={classnames(styles.headerServiceLink, {
          [styles.engbersServiceHeaderIconWrap]: !isEmilio && service.serviceIcon?.media?.file
        })} target={service.serviceLink} title={service.service}>
              {service.serviceIcon?.media?.file && <Image src={service.serviceIcon.media.file} alt={getTitle(service.serviceIcon)} title={getTitle(service.serviceIcon)} width={isEmilio ? 26 : 18} />}
              <span>{service.service}</span>
            </ReferenceLink>
          </li>)}
      </ul>
    </div>;
};